import "./section__about.css";
import logo from "../../../img/logo_main.svg";
function About() {
  return (
    <section className="section__about">
      <div className="main-presentation">
        <div className="logo">
          <img className="logo-image" src={logo} />
          <h1 className="main-presentation__title">OpenGater</h1>
        </div>
        <div className="main-presentation">
          <p className="main-presentation__description">
            Откройте для себя врата в мир
            <br />
            быстрого и свободного
            <br />
            интернета!
          </p>
          <div className="main-presentation__order">
            <a href="https://t.me/opengater_vpn_bot">
              <button className="main-presentation__order-button button-key">
                Подключить
              </button>
            </a>
          </div>
        </div>
      </div>

      {/* <!-- Аdvantages --> */}

      <div className="main-advantages">
        <h2 className="main-advantages__title">Почему OpenGater?</h2>

        <div className="main-advantages__wrapper">
          {/* <!-- Item 1 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">⚡️</p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">
                Гарантировано высокая скорость
              </h4>
              <p className="description">250+ МБ в секунду</p>
            </div>
          </div>

          {/* <!-- Item 2 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">🔥</p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">
                Передовые способы обходы блокировок
              </h4>
              <p className="description">Подключение по протоколу VLESS</p>
            </div>
          </div>

          {/* <!-- Item 3 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">✅ </p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">
                Поддерживаем следующие операционные системы:
              </h4>
              <p className="description">iOS, Android, macOS, Linux</p>
            </div>
          </div>

          {/* <!-- Item 4 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">💳</p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">
                Оплата картами РФ без комиссии
              </h4>
              <p className="description">Все комиссии берём на себя</p>
            </div>
          </div>

          {/* <!-- Item 5 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">🌎</p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">Безопасность</h4>
              <p className="description">Полная анонимность в сети</p>
            </div>
          </div>

          {/* <!-- Item 6 --> */}
          <div className="main-advantages__item">
            <p className="main-advantages__item-image emoji">💵</p>
            <div className="main-advantages__item-description">
              <h4 className="description-title">Стоимость</h4>
              <p className="description">от 99 ₽/мес</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
