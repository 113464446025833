import "./tariffs.css";
import { useState } from "react";
function Tariffs() {
  const bot = "https://t.me/opengater_vpn_bot";
  return (
    <section className="tariff container" id="price">
      <h2 className="tariff__title title">Тарифы</h2>
      <div className="tariff__wrapper">
        {/* <!-- Tariffs --> */}
        <div className="tariff__table">
          {/* <!-- Free --> */}
          <div className="tariff-card">
            {/* <!-- Head --> */}
            <div className="tariff-card__header">
              <div className="tariff-card__header-wrapper">
                <p className="tariff-card__header-icon emoji">🤝</p>
                <h4 className="tariff-card__header-title tariff-card__header-title_yellow">
                  Бесплатный
                </h4>
              </div>
              <p className="tariff-card__header-subtitle">Бесплатно на 3 дня</p>
            </div>
            {/* <!-- Body --> */}
            <div className="tariff-card__body">
              <div className="tariff-card__body-opportunity">
                <h5 className="tariff-card__body-title">Бесплатно</h5>
                <p className="tariff-card__body-subtitle">
                  Будем рады вашим <a href="#">донатам</a> 🍩
                </p>
              </div>
              <a href={bot} target="_blank">
                <button className="tariff-card__body-button tariff-card__body-button_white">
                  Подключиться
                </button>
              </a>
              <ul className="tariff-card__body-features">
                {/* <!-- Item 1 --> */}
                <li>
                  <p className="emoji">🏎️</p>
                  <p>до 200 Мбит/с</p>
                </li>

                {/* <!-- Item 2 --> */}
                <li>
                  <p className="emoji">🗝</p>
                  <p>1 устройство</p>
                </li>

                {/* <!-- Item 3 --> */}
                <li>
                  <p className="emoji">✅</p>
                  <p>Доступ к сайтам</p>
                </li>
              </ul>
            </div>
          </div>

          {/* <!-- Video --> */}
          <div className="tariff-card">
            {/* <!-- Head --> */}
            <div className="tariff-card__header">
              <div className="tariff-card__header-wrapper">
                <p className="tariff-card__header-icon emoji">⚡</p>
                <h4 className="tariff-card__header-title tariff-card__header-title_red">
                  Быстрый
                </h4>
              </div>
              <p className="tariff-card__header-subtitle">
                Для документов, видео и удалённой учёбы.
              </p>
            </div>
            {/* <!-- Body --> */}
            <div className="tariff-card__body">
              <div className="tariff-card__body-opportunity">
                <h5 className="tariff-card__body-title">от 99Р в месяц</h5>

                <p className="tariff-card__body-subtitle">
                  <span>от 549₽</span> при оплате за 6 месяцев
                </p>
                <p className="tariff-card__body-subtitle">
                  <span>от 990Р</span> при оплате за год
                </p>
              </div>

              <a href={bot} id="btn__video-rub" target="_blank">
                <button className="tariff-card__body-button tariff-card__body-button_white">
                  Подключиться
                </button>
              </a>

              <ul className="tariff-card__body-features">
                {/* <!-- Item 1 --> */}
                <li>
                  <p className="emoji">🏎️</p>
                  <p>300+ Мбит/с</p>
                </li>

                {/* <!-- Item 2 --> */}
                <li>
                  <p className="emoji">🗝</p>
                  <p>1 устройство</p>
                </li>

                {/* <!-- Item 3 --> */}
                <li>
                  <p className="emoji">✅</p>
                  <p>Доступ к сайтам</p>
                </li>

                {/* <!-- Item 4 --> */}
                <li>
                  <p className="emoji">✅</p>
                  <p>Работает, пока активна подписка</p>
                </li>

                {/* <!-- Item 5 --> */}
                <li>
                  <p className="emoji">✅</p>
                  <p>Можно смотреть видео и качать большие файлы</p>
                </li>

                {/* <!-- Item 6 --> */}
                <li>
                  <p className="emoji">💙</p>
                  <p>Заботливая поддержка</p>
                </li>

                {/* <!-- Item 7 --> */}
                <li>
                  <p className="emoji">📶</p>
                  <p>Высокая скорость и стабильность</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tariffs;
