import "./section.css";
import About from "./About/about";
import Tariffs from "./tariffs/tariffs";

function Section() {
  return (
    <main className="main container section" id="main">
      <About />
      <Tariffs />
    </main>
  );
}

export default Section;
