import "./header.css";
import pic from "../../img/logo_main.svg";
import React, { useState } from "react";
import Hamburger from "hamburger-react";
function Header() {
  const [isOpen, setOpen] = useState(false);

  return (
    <header className="header">
      <div className="header-logo" href="/">
        <img src={pic} alt="VPN Наоборот" className="header-logo__img" />
        <p className="header__text">OpenGater</p>
      </div>
      <nav className="header-navigation">
        <ul className="header-navigation__list">
          {/* // Main */}
          <li>
            <a href="#main">
              <button className="header-navigation__list-item navigation-button">
                Главная
              </button>
            </a>
          </li>
          {/* // Price */}
          <li>
            <a href="#price">
              <button className="header-navigation__list-item navigation-button">
                Цены
              </button>
            </a>
          </li>
          {/* // Instructions */}
          <li>
            <a  href={"https://t.me/opengater_vpn_bot"} target="_blank">
              <button className="header-navigation__list-item navigation-button">
                Бот
              </button>
            </a>
          </li>
          {/* // Chat */}
          <li>
            <a  href={"https://t.me/opengater_support"} target="_blank">
              <button className="header-navigation__list-item navigation-button">
                Помощь
              </button>
            </a>
          </li>
        </ul>
      </nav>
      <a href={"https://t.me/opengater_vpn_bot"} target="_blank">
        <button className="header__button button-key">Подключить</button>
      </a>
      <Hamburger toggled={isOpen} size={20} toggle={setOpen} className="header__mobile-button"/>

      {/* <!-- Mobile navigation --> */}
      <nav  class={isOpen ? "header-mobile": "header-mobile__close"}>
        <ul class="header-mobile__list">
          <li>
            <a href="#main">
              <button class="header-mobile__list-item navigation-button">
                Главная
              </button>
            </a>
          </li>
          <li>
            <a href="#price">
              <button class="header-mobile__list-item navigation-button">
                Цены
              </button>
            </a>
          </li>
          <li>
            <a href={"https://t.me/opengater_vpn_bot"}>
              <button class="header-mobile__list-item navigation-button">
                Бот
              </button>
            </a>
          </li>
          <li>
            <a href={"https://t.me/opengater_support"}>
              <button class="header-mobile__list-item navigation-button">
                Помощь
              </button>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
