import "./footer.css";
import logo from "../../img/logo_main.svg";
import email from "../../img/email1.svg";
import telegram from "../../img/telegram.svg";
import { useForm } from "react-hook-form";

function Footer() {
  return (
    <footer className="footer">
      <a className="footer__logo" href="index.html">
        <img src={logo} alt="VPN Наоборот" className="footer__logo-img" />
      </a>

      <div className="footer__wrapper">
        {/* <!-- Contacts --> */}

        <div className="footer-contacts">
          {/* <!-- Telegram --> */}
          <a href={"https://t.me/opengater_vpn_bot"} target="_blank">
            <img src={telegram} alt="" />
            <p className="navigation-button">Telegram</p>
          </a>

          {/* <!-- Email --> */}
          <a href="mailto:team@vpn-naoborot.online">
            <img src={email} alt="" />
            <p className="navigation-button">mail@opengater.com</p>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
