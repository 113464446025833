import React from "react";

import "./app.css";

import Header from "../Header/header";
import Section from "../Section/section.js";
import Footer from "../Footer/footer.js";

function App() {
  return (
    <>
      <Header />
      <Section />
      <Footer />
    </>
  );
}

export default App;
